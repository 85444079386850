import React,{ useState,useEffect } from "react";
import styles from "./ChangePassword.module.scss";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthHook } from "../../context/auth";
import { UserService } from "../../utility/services";
import { TextField } from "../../elements/Inputs";
import { ErrorMessages } from "../../utility/constants/common";

const passwordSchema = yup.object().shape({
  currentPassword: yup
    .string()
    .min(4,"Password should be between 4 to 16 characters")
    .max(16,"Password should be between 4 to 16 characters")
    .required("Password is required"),
  newPassword: yup
    .string()
    .min(4,"Password should be between 4 to 16 characters")
    .max(16,"Password should be between 4 to 16 characters")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref("newPassword"),null],
      "New and Confirm Password must match"
    )
    .required(),
});
const ChangePassword = () => {
  const {
    register: changePassword,
    errors,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(passwordSchema),
  });

  const [loading,setLoading] = useState(false);
  const [error,setError] = useState("");
  const [success,setSuccess] = useState("");
  const [profileImage,setProfileImage] = useState("");
  console.log(profileImage);
  const { user,login } = AuthHook();

  useEffect(() => {
    if (user) {
      reset(user);
    }
    // eslint-disable-next-line
  },[user]);
  useEffect(() => {
    setProfileImage(user?.imageUrl);
  },[user]);
  const save = async (data) => {
    try {
      setError("");
      setSuccess("");
      setLoading(true);

      const requestBody = {
        currentPassword: data.currentPassword,
        newPassword: data.newPassword,
      };
      await UserService.changePassword(requestBody);
      login(requestBody);
      setSuccess("Password Updated successfully.");
    } catch (err) {
      setError(ErrorMessages.default || "Invalid Password");
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div className={styles.popup_box}>
        <form noValidate onSubmit={handleSubmit(save)}>
          <div className={`${styles.password} form-group`}>
            <TextField
              type="password"
              ref={changePassword}
              placeholder="Enter Current Password"
              name="currentPassword"
              errors={errors?.currentPassword}
              hideErrors={true}
              color="#000000"
            />
          </div>

          <div className={`${styles.password} form-group`}>
            <TextField
              type="password"
              ref={changePassword}
              placeholder="Enter New Password"
              name="newPassword"
              errors={errors?.newPassword}
              hideErrors={true}
              color="#000000"
            />
          </div>
          <div className={`${styles.password} form-group`}>
            <TextField
              type="password"
              ref={changePassword}
              placeholder="Confirm Password"
              name="confirmPassword"
              errors={errors?.confirmPassword}
              hideErrors={true}
              color="#000000"
            />
          </div>

          <div className="form-group">
            <button
              type="submit"
              disabled={loading}
              className="btn btn-next w-100"
            >
              {loading ? "Saving..." : "SAVE"}
            </button>
          </div>
          {success && <label className="text-black">{success}</label>}
          {error && <label className="text-danger">{error}</label>}
        </form>
      </div>
    </>
  );
};

export default ChangePassword;
