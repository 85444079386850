import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import styles from "./Forgot-Screen.module.scss";
import { Spinner } from "reactstrap";
import { UserService } from "../../utility/services";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessages } from "../../utility/constants/common";
import { Link } from "react-router-dom";
import { TextField } from "../../elements/Inputs";

const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid Email Address")
    .required("Email address is required"),
});

const ForgotScreen = () => {

  const {
    register: forgotPassword,
    errors,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(forgotPasswordSchema),
  });
  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const onSubmit = async (data) => {
    try {
      setSuccess(false);
      setLoading(true);
      await UserService.forgotPassword({ email: data.email });
      setSuccess(true);
    } catch (err) {
      setError(ErrorMessages.badEmail);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <div className={styles.bg_image}>
        <Row className={`${styles.main_row} gx-0`}>
          <Col md={6} sm={12} className={styles.reg_col}>
            <div className={styles.container}>
              <div className={styles.heading}>
                <h3>FORGOT PASSWORD</h3>
              </div>
              {!success ? (
                <form
                  name="formLogin"
                  noValidate
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className={styles.email}>
                    <TextField
                      ref={forgotPassword}
                      errors={errors?.email}
                      placeholder="Email Address"
                      name="email"
                      hideErrors={true}
                    />
                  </div>
                  {errors?.email?.message && (
                    <div className={styles.error}>
                      {errors?.email?.message}.
                    </div>
                  )}

                  {error && <div className={styles.error}>{error}</div>}

                  <div className={styles.reg_btn}>
                    <button type="submit">
                      {loading ? (
                        <Spinner animation="border" variant="light" />
                      ) : (
                        "SEND EMAIL"
                      )}
                    </button>
                  </div>
                </form>
              ) : (
                <p className={styles.success}>
                  Email has been sent to your email address, please check your
                  inbox to reset your password.
                </p>
              )}
              <div className={styles.have_account}>
                <Link to={"/"}>Back to Login</Link>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ForgotScreen;
