import React from "react";
import styles from "./Footer.module.scss";
// import { Row, Col } from "reactstrap";
import logo from "../../Assets/logo_light.svg";
import metaworks from "../../Assets/metaworks-logo.png";
// import fb from "../../Assets/fb.svg";
// import twitter from "../../Assets/twitter.svg";
// import instagram from "../../Assets/instagram.svg";
// import tiktok from "../../Assets/tiktok.svg";
// import youtube from "../../Assets/youtube.svg";
// import twitch from "../../Assets/twitch.svg";

import BottomFooter from "./BottomFooter.jsx/BottomFooter";

const Footer = () => {
  return (
    <div className={styles.main_container}>
      <div className={`${styles.row} gx-0`}>
        <div className={styles.col_one}>
          <div>
            <a href="https://musicfx.io/" target="_blank" rel="noreferrer">
              <img src={logo} alt="MusicFX" />
            </a>
          </div>
          <ul>
            <li>
              <a href="https://musicfx.io" target="_blank" rel="noreferrer">
                Home
              </a>
            </li>
            <li>
              <a rel="noreferrer" href="https://musicfx.io/about/">
                About
              </a>
            </li>
            <li>
              <a href="https://musicfx.io/support/" rel="noreferrer">
                Support
              </a>
            </li>
          </ul>
        </div>
        <div className={styles.col_two}>
          <div>
            <a className={styles.anchor_link} href="/#">
              <h2>ABOUT</h2>
            </a>
          </div>

          <p>
            MusicFX is the hub for fans and artists to connect through digital
            innovations and engagement.
          </p>
        </div>
        <div className={styles.col_three}>
          <div>
            <a className={styles.anchor_link} href="/#">
              <h2>CONTACT</h2>
            </a>
          </div>

          <p className="m-0">
            <span>
              Contact:{" "}
              <a href="mailto:contact@musicfx.io">contact@musicfx.io</a>
            </span>
          </p>
          <p className="m-0">
            <span>
              Media Enquiries:{" "}
              <a href="mailto:media@musicfx.io">media@musicfx.io</a>
            </span>
          </p>
        </div>
        <div className={styles.col_four}>
          <div>
            <h2>POWERED BY</h2>
          </div>

          <div className={styles.currency_works}>
            <a
              href="https://currencyworks.io/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={metaworks} alt="Metaworks" />
            </a>
          </div>
        </div>
      </div>
      <BottomFooter />
    </div>
  );
};

export default Footer;
