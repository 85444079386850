import React,{ useMemo,useState } from "react";
import styles from "./style.module.scss";
import { Button,Row,Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import AudioPlayer from "react-h5-audio-player";
import "./override.scss";
import { cardsToShowUserName } from "../../utility/constants/common";

const Audio = ({ details }) => {

  const history = useHistory();
  const [hoverText,setHoverText] = useState("COPY LINK TO SHARE");

  const showUserName = useMemo(() => details?.release,cardsToShowUserName?.includes((details?.release || '').toLowerCase()),
    [details,cardsToShowUserName])

  const copyToClipboard = () => {
    const textArea = document.createElement("textarea");
    textArea.value = window.location.href;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();
    setHoverText("COPIED TO CLIPBOARD");
    document.getElementById("Copy").style.backgroundColor = "green";
    setTimeout(() => {
      document.getElementById("Copy").style.backgroundColor = "black";
      setHoverText("COPY LINK TO SHARE");
    },1500);
  };
  return (
    <div className={styles.main_container}>
      <div className={styles.buttons_container}>
        <Button onClick={() => history.push("/app/dashboard")}>
          BACK TO MY DASHBOARD
        </Button>
        <Button onClick={copyToClipboard} id="Copy">
          {hoverText}
        </Button>
      </div>
      <div className={`${styles.second_container} container`}>
        <Row className={`${styles.main_div} gx-0`}>
          <Col md={12} lg={6} sm={12} className={styles.col1_container}>
            <img
              src={details?.icon?.url}
              alt="Card"
              className={styles.imgContainer}
            />
            {showUserName && <p>{details?.owner?.fullName}</p>}
          </Col>
          <Col md={12} lg={6} sm={12} className={styles.col2_container}>
            <p className={styles.heading}>{details?.name}</p>
            <p>Your Membership Includes the following:</p>
            {details?.text ? (
              <div
                className={styles.detail_div}
                contentEditable="false"
                dangerouslySetInnerHTML={{ __html: details?.text }}
              ></div>
            ) : null}
            <p>Membership Number: {details?.groupSequence}</p>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12} sm={12}>
            {details?.attachments ? (
              details?.attachments?.map((attachment,i) => (
                <div key={i} className={styles.player_container}>
                  {/* <p>{attachment?.name}</p> */}

                  <AudioPlayer
                    autoPlay
                    src={attachment?.url}
                    onPlay={() => console.log("onPlay")}
                  />
                </div>
              ))
            ) : (
              <h1>No Data Found</h1>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Audio;
