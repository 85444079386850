import { APIPath } from '../constants/api';
import { BaseService } from './base';
import { CRUDService } from './crud';

class Packs extends CRUDService {

    constructor() {
        super(APIPath.packs)
    }

    my() {
        return BaseService.get(APIPath.myPacks);
    }

    buy(id,reqData) {
        return BaseService.post(`${APIPath.buyPack}/${id}`,reqData);
    }

    open(id) {
        return BaseService.get(`${APIPath.openPack}/${id}`);
    }

}

const PacksService = new Packs();
Object.freeze(PacksService);
export { PacksService };
