
import { useLocation } from 'react-router-dom';
import { useLayoutEffect } from 'react'

export const ScrollToTop = () => {
    const { pathname } = useLocation();
    // Scroll to top if path changes
    useLayoutEffect(() => {
        window.scrollTo(0,0);
    },[pathname]);

    return null;
}
