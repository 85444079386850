import React, { useState, useEffect } from "react";
import styles from "./SideNav.module.scss";
import { Link, useHistory } from "react-router-dom";
import NavHeaderLogo from "../../../Assets/logo_light.svg";
import Logo from "../../../Assets/logo_blue.svg";
import User from "../../../Assets/user.svg";
import { Button } from "reactstrap";
import { UserService } from "../../../utility/services";
import { AuthHook } from "../../../context/auth";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

function SideNav() {
  const history = useHistory();
  const [SideNavOpen, setSideNavOpen] = useState(false);
  const {
    isAuthenticated: isLoggedIn,
    logout: logoutContext,
    user,
  } = AuthHook();

  useEffect(() => {
    const targetElement = document.querySelector("body");
    SideNavOpen
      ? disableBodyScroll(targetElement)
      : enableBodyScroll(targetElement);
  }, [SideNavOpen]);

  const logout = () => {
    UserService.logout();
    logoutContext();
    history.push("/");
  };

  return (
    <div className={styles.main_div}>
      <div className={styles.menuButton}>
        <div className={styles.togButton}>
          <button onClick={(e) => setSideNavOpen(true)}>
            <i className="fas fa-bars"></i>
          </button>
        </div>
        <div className={styles.smLogo}>
          <a href="https://musicfx.io/" target="_blank" rel="noreferrer" >
            <img src={NavHeaderLogo} alt="MusicFX" />
          </a>
        </div>
      </div>
      {SideNavOpen ? (
        <div className={styles.sideNav}>
          <div className={styles.sideNavUp}>
            {isLoggedIn ? (
              <img src={user?.imageUrl || User} alt="website logo" />
            ) : (
              <img src={Logo} alt="user" />
            )}
            <button onClick={(e) => setSideNavOpen(false)}>
              <i className="fas fa-times"></i>
            </button>
          </div>
          <ul>
            {isLoggedIn && (
              <>
                <Link to="/app/marketplace" className={styles.link}>
                  <li>marketplace</li>
                </Link>
                <Link to="/app/dashboard" className={styles.link}>
                  <li>dashboard</li>
                </Link>
              </>
            )}

            <Link to="/" className={styles.link}>
              <li>home</li>
            </Link>
            <Link  to={{ pathname: `https://musicfx.io/about-us/` }}
                  target="_blank" className={styles.link}>
              <li>about</li>
            </Link>
            <Link to={{ pathname: `https://musicfx.io/release/` }}
                  target="_blank" className={styles.link}>
              <li>releases</li>
            </Link>
            <Link  to={{ pathname: `https://musicfx.io/support/` }}
                  target="_blank" className={styles.link}>
              <li>support</li>
            </Link>
            <Link to={{ pathname: `https://musicfx.io/podcasts/` }}
                  target="_blank" className={styles.link}>
              <li>PODCASTS</li>
            </Link>
              
            {isLoggedIn && (
              <Link to="/edit-profile" className={styles.link}>
                <li>Edit Profile</li>
              </Link>
            )}

            {user?.hasPassword && (
              <Link to="/change-password" className={styles.link}>
                <li>Change Password</li>
              </Link>
            )}

            <div className={styles.bottomButton}>
              {isLoggedIn ? (
                <Button className={styles.loginButton} onClick={() => logout()}>
                  Logout
                </Button>
              ) : (
                <Button
                  className={styles.loginButton}
                  onClick={(e) => history.push("/")}
                >
                  Login
                </Button>
              )}
            </div>
          </ul>
        </div>
      ) : null}
    </div>
  );
}

export default SideNav;
