import React from "react";
import styles from "./Dashboard.module.scss";
import { Card, CardImg, CardBody, CardSubtitle, Row, Col } from "reactstrap";
import "./override.scss";
import { useHistory } from "react-router-dom";
import { CardsHook, TotalNftsHook } from "../../hooks/cards";
import { PacksHook } from "../../hooks/packs";
import Loader from "../../Components/Loader/Loader";

const Dashboard = () => {
  const history = useHistory();

  const { data, loading, seeMore, hasMore } = CardsHook();
  const { totalNft } = TotalNftsHook();
  const { loadingPack } = PacksHook();

  if (loading || loadingPack) {
    return <Loader />;
  }
  console.log(totalNft, "total");
  return (
    <div className={styles.main_container}>
      <h1 className="d-flex justify-content-center">
        {totalNft &&
          `Total number of NFTs: ${totalNft}
        `}
      </h1>
      <Row className={`${styles.row} gx-0`}>
        {data?.length ? (
          data.map((item) => (
            <Col
              sm={12}
              md={6}
              lg={3}
              className={styles.left_col}
              key={item.id}
            >
              <Card className="h-100">
                <CardImg
                  top
                  width="100%"
                  src={item?.icon?.url}
                  alt="Card image cap"
                />

                <CardBody className="d-flex justify-content-end flex-column">
                  <div className={styles.divider}>
                    <div className={styles.left}>
                      <CardSubtitle>{item?.name}</CardSubtitle>
                    </div>
                    <div className={styles.right}>
                      <button
                        onClick={() => history.push(`/view-card/${item?.id}`)}
                      >
                        VIEW NFT
                      </button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))
        ) : (
          <h1 style={{ margin: "auto", color: "black", textAlign: "center" }}>
            No Data Found.
          </h1>
        )}
      </Row>
      {hasMore ? <button onClick={seeMore}>Load more</button> : null}
    </div>
  );
};

export default Dashboard;
