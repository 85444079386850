


import CommonUtility from '../common';
import { APIPath } from '../constants/api';
import { BaseService } from './base';
import { CRUDService } from './crud';

class Cards extends CRUDService {

    constructor() {
        super(APIPath.cards)
    }

    my(filter) {
        const params = CommonUtility.objectToParams(filter)
        return BaseService.get(`${APIPath.myCards}?${params}`);
    }
    totalNfts() {
        return BaseService.get(`${APIPath.totalNfts}`);
    }

    sell(id,price) {
        return BaseService.put(`${APIPath.sellCard}/${id}/${price}`,null);
    }

    cancel(id) {
        return BaseService.put(`${APIPath.sellCard}/${id}/cancel`,null);
    }

    buy(id,reqData) {
        return BaseService.post(`${APIPath.buyCard}/${id}`,reqData);
    }

    history(id) {
        return BaseService.get(`${APIPath.cardHistory}/${id}`);
    }

}

const CardsService = new Cards();
Object.freeze(CardsService);
export { CardsService };
