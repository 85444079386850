
export default class CommonUtility {

  static currencyFormat = (value,currency) =>
    new Intl.NumberFormat("en-US",{
      style: "currency",
      currency: currency || "USD",
    }).format(value || 0);

  static isNotEmpty = (item) =>
    item !== undefined && item !== null && item.length !== 0;


  static truncateString = (text,ellipsisString) =>
    (text || "").length > ellipsisString
      ? text.substring(0,ellipsisString) + "..."
      : text;

  static objectToParams = (obj) => {
    let str = "";
    for (const key in obj) {
      if (str !== "") {
        str += "&";
      }
      str += key + "=" + encodeURIComponent(obj[key]);
    }
    return str;
  };

  static getExtension = (url) => {
    return url.split(/[#?]/)[0].split('.').pop().trim();
  }

}
