import React,{ useState,useEffect } from "react";
import Header from "../../Components/Header/Header";
import BuyNft from "../../Components/BuyNft/BuyNft";
import Footer from "../../Components/Footer/Footer";
import styles from "./BuyNft.module.scss";
import { PackTemplateDetailsHook } from '../../hooks/pack-templates';
import { useLocation } from "react-router-dom";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { BrandingHook } from '../../hooks/user'
import Loader from '../../Components/Loader/Loader';


let stripePromise;

const BuyNftPage = () => {
    const [id,setId] = useState('')
    const [type,setType] = useState('')
    let { search } = useLocation();

    useEffect(() => {
        const id = new URLSearchParams(search).get("id");
        if (id) {
            setId(id)
        }
        const type = new URLSearchParams(search).get("type");
        if (type) {
            setType(type)
        }
    },[search]);

    const { data,loading } = PackTemplateDetailsHook(id);
    const { data: branding } = BrandingHook();

    useEffect(() => {
        if (branding && branding?.stipePublishableKey) {
            stripePromise = loadStripe(branding?.stipePublishableKey);
        }
    },[branding]);


    if (loading) {
        return <Loader />
    }
    return (
        branding?.stipePublishableKey && stripePromise ?
            <Elements stripe={stripePromise}>
                <div>
                    <Header />
                    {/* <Hero title="MARKETPLACE" /> */}

                    <div className={styles.nft}>
                        <BuyNft item={data} type={type} id={id} />
                    </div>
                    <Footer />
                </div>
            </Elements> : <Loader />
    )
}

export default BuyNftPage;
