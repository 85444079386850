import React from 'react'
import style from "./LoadingBar.module.scss";

export const LoadingBar = ({ dark }) => (
    <div className="row">
        <div className="col-12">
            <div className={style.loadingContainer}>
                <div className={`spinner ${dark ? "dark" : "light"}`}>
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                </div>
            </div>
        </div>
    </div>
)

export const OverlayLoadingBar = ({ dark,title }) => (
    <div className={style.container}>
        <LoadingBar dark={dark} />
        <div className="row">
            <div className="col-12">
                <p>{title}</p>
            </div>
        </div>
    </div>
)