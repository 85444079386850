import React,{ useState,useEffect } from "react";
import { Row,Col } from "reactstrap";
import styles from "./Verify.module.scss";
import { Link,useLocation } from "react-router-dom";
import { Spinner } from "reactstrap";
import { UserService } from "../../utility/services";
import { ErrorMessages } from "../../utility/constants/common";

const Verify = () => {

  let { search } = useLocation();

  const [success,setSuccess] = useState(false);
  const [error,setError] = useState('');
  const [loading,setLoading] = useState(false);

  const onSubmit = async (key) => {
    try {
      setError('');
      setLoading(true);
      await UserService.registerActivate(key);
      setSuccess(true);
    } catch (err) {
      setError(err?.error?.detail || ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const key = new URLSearchParams(search).get("key");
    if (key) {
      onSubmit(key)
    } else {
      setError("Invalid Link, Please contact administrator");
    }
  },[search]);


  return (
    <div>
      <div className={styles.bg_image}>
        <Row className={`${styles.main_row} gx-0`}>
          <Col md={6} sm={12} className={styles.reg_col}>
            <div className={styles.container}>
              <div className={styles.heading}>
                <h3>Account Verification</h3>
              </div>

              <div className={styles.have_account}>
                {
                  loading ?
                    <Spinner animation="border" variant="primary" /> : <>
                      {success && <p className='mt-3'>
                        Your registration has been completed.
                      </p>}
                      {error && <p className='text-danger mt-3'>
                        {error}
                      </p>}
                      <Link to='/' className='linkDefalut'>
                        Back to login
                      </Link>
                    </>

                }
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Verify;
