import React from 'react'
import Footer from "../../Components/Footer/Footer";
import Hero from "../../Components/Hero/Hero";
import Header from "../../Components/Header/Header";
import Video from "../../Components/CardDetails/Video";
import Audio from "../../Components/CardDetails/Audio";
import styles from "./CardDetails.module.scss"
import { CardDetailsHook } from '../../hooks/cards';
import { useParams } from 'react-router';
import Loader from '../../Components/Loader/Loader';

const CardDetails = () => {

    let { id } = useParams();
    const { data, loading } = CardDetailsHook(id);

    if (loading) {
        return <Loader />
    }
    return (
        <div>
            <Header />
            <div className={styles.div}>
                {
                    data?.assetType === "MUSIC" ? <Audio details={data} /> : <Video details={data} />
                }

            </div>
            {/* <Third/> */}
            <Footer />
        </div>
    )
}

export default CardDetails
