import React,{ useState,useEffect } from "react";
import styles from "./EditProfile.module.scss";
import { Row,Col } from "reactstrap";
import { AuthHook } from "../../context/auth";
import { UserService } from "../../utility/services";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import ChangePassword from "../Change-Password/ChangePassword";
import { Button,ButtonGroup } from "reactstrap";
import { useParams } from "react-router";

const EditProfileForm = ({ userData,handleChange,loading,handleSubmit }) => {
  return (
    <>
      <div className="form-group">
        <input
          placeholder="First Name"
          value={userData?.firstName}
          name="firstName"
          onChange={(e) => handleChange(e)}
        />
      </div>
      <div className="form-group">
        <input
          value={userData?.lastName}
          placeholder="Last Name"
          name="lastName"
          onChange={(e) => handleChange(e)}
        />
      </div>

      <div className="form-group">
        <input
          placeholder="Mobile Number"
          name="mobileNumber"
          value={userData?.mobileNumber}
          onChange={(e) => handleChange(e)}
        />
      </div>
      <div className="form-action">
        <button onClick={handleSubmit} className="btn btn-next w-100">
          {loading ? "Loading" : "SAVE"}
        </button>
      </div>
    </>
  );
};

const EditProfile = () => {
  const [userData,setUserData] = useState({});
  const [loading,setLoading] = useState("");
  const { user,login } = AuthHook();

  useEffect(() => {
    setUserData({ ...user });
  },[user]);

  const handleChange = (e) => {
    e.preventDefault();
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const body = {
        ...user,
        ...userData,
      };
      await UserService.add(body);
      login(body);
    } finally {
      setLoading(false);
    }
  };

  const { key } = useParams();

  const [tab,setTab] = useState();

  useEffect(() => {
    if (key) {
      setTab(key);
    } else {
      setTab("edit-profile")
    }
  },[key]);

  const styleHandler = (selected) => {
    if (tab === selected) {
      return styles.button_active;
    } else {
      return styles.button_inactive;
    }
  };

  return (
    <>
      <Header />
      <div className={styles.payment}>
        <div className="header-background">
          <div className={styles.container}>
            <Row className={`${styles.box} gx-0`}>
              <Col lg={6} md={12} sm={10} xs={10} className={styles.col}>
                <div className={styles.popup_box}>
                  <label className={styles.popup_title}>
                    Welcome, {userData?.firstName}!
                  </label>

                  <div className={styles.group_button_container}>
                    <ButtonGroup className={styles.divider}>
                      <Button
                        className={styleHandler("edit-profile")}
                        onClick={(e) => setTab("edit-profile")}
                      >
                        Edit Profile
                      </Button>
                      <Button
                        className={styleHandler("change-password")}
                        onClick={(e) => setTab("change-password")}
                      >
                        Change Password
                      </Button>
                    </ButtonGroup>
                  </div>

                  <div>
                    {tab === "edit-profile" && <EditProfileForm
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      userData={userData}
                      loading={loading}
                    />}
                    {tab === "change-password" && <ChangePassword />}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EditProfile;
