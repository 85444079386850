import { useEffect, useState } from "react";
import { CardsService } from "../utility/services";
import { CommonConstant, ErrorMessages } from "../utility/constants/common";

export const CardsHook = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [filter, setFilter] = useState({
    page: 0,
    size: CommonConstant.defaultPageSize,
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await CardsService.my(filter);
      setData(data.concat(result || []));
      setHasMore((result || []).length === filter.size);
    } catch (error) {
      setError(error.message || ErrorMessages.default);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (filter) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [filter]);

  const pageChanged = () => {
    const temp = {
      ...filter,
      page: filter.page + 1,
    };
    setFilter({ ...temp });
  };
  const seeMore = () => {
    setFilter({ ...filter, page: filter.page + 1 });
  };

  return { data, loading, error, pageChanged, hasMore, seeMore };
};
export const TotalNftsHook = () => {
  const [totalNft, setTotalNft] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await CardsService.totalNfts();
      setTotalNft(result);
    } catch (error) {
      setError(error.message || ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { totalNft, loading, error, setTotalNft };
};
export const CardDetailsHook = (id) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await CardsService.getById(id);
      setData(result);
    } catch (error) {
      setError(error.message || ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [id]);

  return { data, loading, error, setData };
};

export const CardHistoryHook = (id) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await CardsService.history(id);
      setData(result);
    } catch (error) {
      setError(error.message || ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [id]);

  return { data, loading, error };
};
