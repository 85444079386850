import React from 'react';
import Footer from "../../Components/Footer/Footer";
import Login from "../../Components/Login/Login";
import Header from "../../Components/Header/Header";

const LoginPage = () => {
    return (
        <div>
            <Header/>
            <Login/>
            <Footer/>
        </div>
    )
}

export default LoginPage;
