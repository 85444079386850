import React, { useState, forwardRef } from "react";
import "react-phone-number-input/style.css";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";

export const TextField = forwardRef(
  ({ name, placeholder, hideErrors, errors, type,color, ...rest }, ref) => {
    const [showPassword, setShowPassword] = useState(false);
    const handleShowPassword = () => {
      setShowPassword(!showPassword);
    };
    return (
      <div 
      // class='contents w-100 position-relative'
      style={{display:'contents'}}
      >
        <input
        // class='position-relative'
          name={name}
          placeholder={placeholder}
          ref={ref}
          type={showPassword ? "text" : type}
          className={`textfield-1 ${errors?.message ? "is-invalid" : ""}`}
          {...rest}
        />
        {!hideErrors && errors?.message && (
          <div className="invalid-feedback">{errors?.message}.</div>
        )}
        {type === "password" && (
          // <div class="  end-0 position-absolute">
            <div
              role="button"
              // class="position-absolute end-0 top-0 mt-2 mr-3"
              onClick={() => handleShowPassword()}
            >
              {showPassword && <BsEye color={color} />}
              {!showPassword && <BsEyeSlash color={color} />}
            </div>
          // </div>
        )}
      </div>
    );
  }
);

export const MobileFormField = ({
  control,
  name,
  placeholder,
  errors,
  ...rest
}) => (
  <>
    <PhoneInputWithCountry
      name={name}
      placeholder={placeholder}
      control={control}
      {...rest}
    />
  </>
);
