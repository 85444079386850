import { createContext,useContext } from "react";
import React from 'react';
import { BrandingHook } from "../hooks/user";

const BrandingContext = createContext();

export const BrandingProvider = ({ children }) => {

    const { data,loading } = BrandingHook();

    return (
        <BrandingContext.Provider value={{ data,loading }}>
            {children}
        </BrandingContext.Provider>
    )
}

export const BrandingContextHook = () => useContext(BrandingContext)
