import React,{ useState } from "react";
import styles from "./Congratulation.module.scss";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";

const Congratulation = ({ show,onHide }) => {

  const history = useHistory();
  const [hoverText,setHoverText] = useState('COPY LINK TO SHARE')

  const copyToClipboard = () => {
    const textArea = document.createElement("textarea");
    textArea.value = window.location.href;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();
    setHoverText("COPIED TO CLIPBOARD");
    document.getElementById('Copy').style.backgroundColor = "green";
    setTimeout(() => {
      document.getElementById('Copy').style.backgroundColor = "black";
      setHoverText('COPY LINK TO SHARE')
    },1500);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className={styles.body}>
          <div className={styles.inner_div}>
            <h2>
              Congratulations!
            </h2>
            <div className={styles.buttonWrapper}>
              <button onClick={() => history.push("/app/dashboard")}>VIEW NFT</button>
              <button onClick={() => history.push("/app/dashboard")}>GO TO DASHBOARD</button>
              <button id="Copy" onClick={copyToClipboard}>{hoverText}</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default Congratulation;
