import React from "react";
import styles from "./MarketPlace.module.scss";
import { CardText,Row,Col } from "reactstrap";
import "./override.scss";
import { useHistory } from "react-router-dom";
import { PackTemplatesHook } from "../../hooks/pack-templates";
import CommonUtility from "../../utility/common";
import Loader from "../../Components/Loader/Loader";

const MarketPlace = () => {
  const history = useHistory();
  const { data,loading } = PackTemplatesHook();
  if (loading) {
    return <Loader />;
  }
  return (
    <div className={styles.main_container}>
      <Row className={`${styles.row} gx-0`}>
        {data?.length ? (
          data.map((item) => (
            <Col
              lg={4}
              md={6}
              sm={12}
              className={styles.left_col}
              key={item.id}
            >
              <div className={styles.card_container}>
                <section className={styles.top_img_sec}>
                  <img src={item?.picture?.url} alt="top" />
                </section>
                <div className={styles.divider}>
                  <div className={styles.left}>
                    <CardText>
                      {item.name}
                    </CardText>
                  </div>
                  <div className={styles.right}>
                    {/* {(item.quantity || 0) > 0 && <CardText>{item.quantity} remaining</CardText>} */}
                    <CardText>
                      {CommonUtility.currencyFormat(item.price / 100)} USD
                    </CardText>
                  </div>
                </div>
                <div className={styles.content_container}>
                  {(item.quantity || 0) > 0 ? (
                    <button
                      onClick={() =>
                        history.push(`/buy-nft?id=${item.id}&type=pack`)
                      }
                    >
                      {" "}
                      <h4>BUY NOW</h4>{" "}
                    </button>
                  ) : (item.quantity || 0) === -21 ? (
                    <button>
                      {" "}
                      <h4> Coming Soon</h4>{" "}
                    </button>
                  ) : (
                    <button> Sold Out</button>
                  )}
                </div>
              </div>
            </Col>
          ))
        ) : (
          <h1 style={{ margin: "auto",color: "black",textAlign: "center" }}>
            No Data Found.
          </h1>
        )}
      </Row>
    </div>
  );
};

export default MarketPlace;
