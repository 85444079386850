import AppRoutes from "./AppRoutes/AppRoutes";
import { AuthProvider } from "./context/auth";
import { BrandingProvider } from "./context/branding";

function App() {
  console.warn = () => {};
  return (
    <div>
      <BrandingProvider>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </BrandingProvider>
    </div>
  );
}

export default App;
