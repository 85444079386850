import React from "react";
import { Route,Redirect } from "react-router-dom";
import { BrowserUtility } from "../../utility/browser-utility";
import { CommonConstant } from "../../utility/constants/common";

const PublicRoute = ({ component: Component,restricted,...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>

        BrowserUtility.get(CommonConstant.token) ? (
          <Redirect to="/app/marketplace" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
