import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import styles from "./Register.module.scss";
import { useHistory } from "react-router-dom";
import GoogleLogin from "react-google-login";
import { UserService } from "../../utility/services";
import { AuthHook } from "../../context/auth";
import { CommonConstant, ErrorMessages } from "../../utility/constants/common";
import { Spinner } from "reactstrap";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField, MobileFormField } from "../../elements/Inputs";
import moment from "moment";

const registerSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(4, "Password should be must be between 4 to 16")
    .max(16, "Password should be must be between 4 to 16"),
  email: yup
    .string()
    .required("Email is required")
    .email("Invalid Email Address"),
  mobileNumber: yup.string().nullable().required("Mobile number is required"),
  firstName: yup.string().trim().required("Firstname is required"),
  lastName: yup.string().trim().required("Lastname is required"),
  zipCode: yup.string().trim().required("Zip Code is required"),
  birthDate: yup.date().required("Birthday is Required"),
  address: yup
    .string()
    .min(2, "Address is Too Short!")
    .max(50, "Address is Too Long!")
    .required("Address is required"),
});

const Register = () => {
  const { register, errors, handleSubmit, control } = useForm({
    resolver: yupResolver(registerSchema),
  });

  const history = useHistory();
  const [success, setSuccess] = useState(false);
  const [disable, setDisable] = useState(true);
  const [disable1, setDisable1] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [emailSubscribe, setEmailSubscribe] = useState(false);

  const { login: loginContext } = AuthHook();

  const Login = () => {
    history.push("/");
  };

  const afterLogin = (result) => {
    UserService.storeToken(result.id_token);

    setTimeout(async () => {
      const user = await UserService.get();
      UserService.storeUser(user);
      loginContext(user);
      history.push("/app/marketplace");
    });
  };

  const googleLogin = async (tokenId) => {
    try {
      setLoading(true);
      const result = await UserService.login({
        googleIdToken: tokenId,
      });
      afterLogin(result);
    } catch (err) {
      console.log(err);
      setError(ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  const googleLoginInit = (data) => {
    googleLogin(data.tokenId);
  };

  const onSubmit = async (data) => {
    try {
      let body = {
        ...data,
        birthDate: moment(data?.birthDate).format("YYYY-MM-DD"),
        emailSubscribe,
      };
      setLoading(true);
      const req = {
        ...body,
        login: "any",
      };

      await UserService.register(req);
      setSuccess(true);
    } catch (err) {
      setError(err?.error?.title || ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  const toggle = () => {
    setDisable(!disable);
  };
  const toggle1 = () => {
    setDisable1(!disable1);
  };
  console.log(moment("2020-06-24 22:57:36").format("YYYY-MM-DD"));
  return (
    <div className={styles.bg_image}>
      <Row className={`${styles.main_row} gx-0`}>
        <Col md={12} sm={12} className={styles.reg_col}>
          <form name="formLogin" noValidate onSubmit={handleSubmit(onSubmit)}>
            {success ? (
              <div className={styles.successBox}>
                <div className={styles.heading}>
                  <h3>Register</h3>
                </div>
                <p className="text-center mt-3">
                  Email has been sent to your email address, please check your
                  inbox to activate your account.
                </p>
              </div>
            ) : (
              <div className={styles.container}>
                <div className={styles.heading}>
                  <h3>Register</h3>
                </div>
                <div className={styles.google_wrapper}>
                  <GoogleLogin
                    clientId={CommonConstant.googleClientId}
                    buttonText="Sign In with Google"
                    onSuccess={googleLoginInit}
                    cookiePolicy={"single_host_origin"}
                    responseType="code,token"
                    className={styles.reg_google}
                  />
                </div>
                <div className={styles.or}>
                  <p>or</p>
                </div>

                <div className={styles.row}>
                  <div className={styles.col}>
                    <div className={styles.fname}>
                      <TextField
                        ref={register}
                        hideErrors={true}
                        errors={errors?.firstName}
                        placeholder="First Name"
                        name="firstName"
                      />
                    </div>
                    {errors?.firstName?.message && (
                      <div className={styles.error}>
                        {errors?.firstName?.message}.
                      </div>
                    )}
                  </div>

                  <div className={styles.col}>
                    <div className={styles.text_field}>
                      <MobileFormField
                        control={control}
                        defaultCountry="US"
                        errors={errors?.mobileNumber}
                        placeholder="Mobile Number"
                        name="mobileNumber"
                      />
                    </div>
                    {errors?.mobileNumber?.message && (
                      <div className={styles.error}>
                        {errors?.mobileNumber?.message}.
                      </div>
                    )}
                  </div>
                </div>

                <div className={styles.row}>
                  <div className={styles.col}>
                    <div className={styles.lname}>
                      <TextField
                        ref={register}
                        hideErrors={true}
                        errors={errors?.lastName}
                        placeholder="Last Name"
                        name="lastName"
                      />
                    </div>
                    {errors?.lastName?.message && (
                      <div className={styles.error}>
                        {errors?.lastName?.message}.
                      </div>
                    )}
                  </div>

                  <div className={styles.col}>
                    <div className={styles.text_field_date}>
                      <TextField
                        ref={register}
                        hideErrors={true}
                        errors={errors?.birthDate}
                        placeholder="mm/dd/yy"
                        name="birthDate"
                        type="date"
                      />
                    </div>
                    {errors?.birthDate?.message && (
                      <div className={styles.error}>
                        {errors?.birthDate?.message}.
                      </div>
                    )}
                  </div>
                </div>

                <div className={styles.row}>
                  <div className={styles.col}>
                    <div className={styles.text_field}>
                      <TextField
                        ref={register}
                        hideErrors={true}
                        errors={errors?.email}
                        placeholder="Email Address"
                        name="email"
                      />
                    </div>
                  </div>
                  <div className={styles.col}>
                    <div className={styles.text_field}>
                      <TextField
                        ref={register}
                        hideErrors={true}
                        errors={errors?.address}
                        placeholder="Address"
                        name="address"
                      />
                    </div>
                    {errors?.address?.message && (
                      <div className={styles.error}>
                        {errors?.address?.message}.
                      </div>
                    )}
                  </div>
                </div>

                <div className={styles.row}>
                  <div className={styles.col}>
                    <div className={styles.text_field}>
                      <TextField
                        ref={register}
                        hideErrors={true}
                        errors={errors?.zipCode}
                        placeholder="Zip Code"
                        name="zipCode"
                      />
                    </div>
                    {errors?.email?.message && (
                      <div className={styles.error}>
                        {errors?.email?.message}.
                      </div>
                    )}
                  </div>

                  <div className={styles.col}>
                    <div className={styles.password}>
                      <TextField
                        type="password"
                        ref={register}
                        hideErrors={true}
                        errors={errors?.password}
                        placeholder="Password"
                        name="password"
                        color="#ffffff"
                      />
                    </div>
                    {errors?.password?.message && (
                      <div className={styles.error}>
                        {errors?.password?.message}.
                      </div>
                    )}
                  </div>
                </div>

                <div className={styles.check}>
                  <input
                    type="checkbox"
                    id="check"
                    name="check"
                    value="agree"
                    onChange={toggle}
                    // checked
                  />
                  <label htmlFor="check">
                    By checking here and continuing, I agree to the
                    <span>
                      <a
                        href="https://musicfx.io/terms-of-service/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Terms of Service
                      </a>
                    </span>
                  </label>
                </div>
                <div className={`${styles.check} mt-1`}>
                  <input
                    type="checkbox"
                    id="check"
                    name="emailSubscribe"
                    checked={emailSubscribe}
                    onChange={(e) => setEmailSubscribe(e.target.checked)}
                    // checked
                  />
                  <label htmlFor="check">
                    By checking here, I agree to receive marketing emails.
                  </label>
                </div>
                {error && <div className={styles.error}>{error}</div>}
                <div className={styles.reg_btn}>
                  <button disabled={disable}>
                    {loading ? (
                      <Spinner animation="border" variant="light" />
                    ) : (
                      "CREATE MY ACCOUNT"
                    )}
                  </button>
                </div>
                <div className={styles.have_account}>
                  <p>
                    Already have an account?
                    <span>
                      <a onClick={Login} href>
                        Log In.
                      </a>{" "}
                    </span>
                  </p>
                </div>
              </div>
            )}
          </form>
        </Col>
      </Row>
    </div>
  );
};

export default Register;
