


import { APIPath } from '../constants/api';
import { CRUDService } from './crud';

class Marketplace extends CRUDService {

    constructor() {
        super(APIPath.marketplace)
    }

}

const MarketplaceService = new Marketplace();
Object.freeze(MarketplaceService);
export { MarketplaceService };