import { useEffect,useState } from "react"
import { PacksService } from "../utility/services";
import { ErrorMessages } from "../utility/constants/common";

export const PacksHook = () => {

    const [pack,setData] = useState([]);
    const [loadingPack,setLoading] = useState(false);
    const [error,setError] = useState('');

    const fetchData = async () => {
        try {
            setLoading(true);
            const result = await PacksService.my();
            setData(result);
        } catch (error) {
            setError(error.message || ErrorMessages.default);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    },[])

    return { pack,loadingPack,error,setData };
}

export const PackDetailsHook = (id) => {

    const [data,setData] = useState({});
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState('');

    const fetchData = async () => {
        try {
            setLoading(true);
            const result = await PacksService.getById(id);
            setData(result);
        } catch (error) {
            setError(error.message || ErrorMessages.default);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (id) {
            fetchData();
        }
        // eslint-disable-next-line
    },[id])

    return { data,loading,error };
}