import React from "react";
import styles from "./BottomFooter.module.scss";
import { Row, Col } from "reactstrap";
import fb from "../../../Assets/fb.svg";
import twitter from "../../../Assets/twitter.svg";
import instagram from "../../../Assets/instagram.svg";
import tiktok from "../../../Assets/tiktok.svg";
import youtube from "../../../Assets/youtube.svg";
import twitch from "../../../Assets/twitch.svg";
import "./override.scss";

const BottomFooter = () => {
  return (
    <div>
      <Row className={`${styles.main_container} gx-0`}>
        <div className={styles.border}></div>
        <div className={styles.inner_container}>
          <Col className={styles.col_one} md={5} sm={12}>
            <div className={styles.social_nav}>
              <Row className="gx-0">
                <div className={styles.div}>
                  <Col md={1} lg={1} sm={1}>
                    <a
                      href="https://www.facebook.com/Music-FX-Digital-101314978940493"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {/* <img src={fb} alt="Facebook" width="18px" /> */}
                      <i className="fab fa-facebook-f vertical-icon-align"></i>
                    </a>
                  </Col>
                  <Col md={1} lg={1} sm={1}>
                    <a
                      href="https://twitter.com/MusicFXDigital"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={twitter} alt="Twitter" width="18px" />
                    </a>
                  </Col>
                  {/* <Col md={1} sm={1}>
                  <img src={linkedin} />
                </Col> */}
                  <Col md={1} lg={1} sm={1}>
                    <a
                      href="https://www.instagram.com/musicfxdigital/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={instagram} alt="Instagram" width="18px" />
                    </a>
                  </Col>
                  <Col md={1} lg={1} sm={1}>
                    <a
                      href="https://www.tiktok.com/@musicfxdigital"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={tiktok} alt="Tiktok" width="18px" />
                    </a>
                  </Col>
                  <Col md={1} lg={1} sm={1}>
                    <a
                      href="https://www.twitch.tv/musicfxdigital"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={twitch} alt="twitch" width="18px" />
                    </a>
                  </Col>{" "}
                  <Col md={1} lg={1} sm={1}>
                    <a
                      href="https://www.youtube.com/channel/UCS4MB6a8O9rL1pGBKEv7Jmg"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={youtube} alt="youtube" width="18px" />
                    </a>
                  </Col>
                </div>
              </Row>
            </div>
            <p>&copy; 2023 MusicFX. All rights reserved.</p>
          </Col>
          <Col className={styles.col_two} md={7} sm={12}>
            <div className={styles.div}>
              <div className={styles.div_one}>
                <p>
                  <a
                    href="https://musicfx.io/privacy-policy/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </p>
              </div>
              <div className={styles.div_two}>
                <p>
                  <a
                    href="https://musicfx.io/terms-of-service/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Terms of Service
                  </a>
                </p>
              </div>
            </div>
          </Col>
        </div>
      </Row>
    </div>
  );
};

export default BottomFooter;
