import React,{ useState } from "react";
import { Row,Col } from "reactstrap";
import styles from "./Login.module.scss";
import { useHistory,Link } from "react-router-dom";
import GoogleLogin from 'react-google-login';
import { UserService } from "../../utility/services";
import { AuthHook } from "../../context/auth";
import { CommonConstant,ErrorMessages } from "../../utility/constants/common";
import { Spinner } from "reactstrap";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from '../../elements/Inputs';
import MusicFxLogoBig from "../../Assets/redesign/m8.png";

const loginSchema = yup.object().shape({
  password: yup.string().required("Password is required"),
  username: yup.string().required("Email Address is required").email("Invalid Email Address"),
  rememberMe: yup.boolean()
});

const Login = () => {

  const history = useHistory();

  const {
    register: login,
    errors,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(loginSchema)
  });

  const { login: loginContext } = AuthHook();

  const [loading,setLoading] = useState(false);
  const [error,setError] = useState("");


  const afterLogin = (result) => {
    UserService.storeToken(result.id_token);

    setTimeout(async () => {
      const user = await UserService.get();
      UserService.storeUser(user);
      loginContext(user);
      history.push("/app/marketplace");
    });
  };

  const googleLogin = async (tokenId) => {
    try {
      setLoading(true);
      const result = await UserService.login({
        googleIdToken: tokenId,
      });
      afterLogin(result);
    } catch (err) {
      setError(ErrorMessages.default);
    } finally {
      setLoading(false);
    }
  };

  const googleLoginInit = (data) => {
    googleLogin(data.tokenId);
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      const req = {
        ...data,
        rememberMe: false
      };

      const result = await UserService.login(req);
      afterLogin(result);
    } catch (err) {
      const error =
        err?.error?.detail === "Bad credentials"
          ? "Invalid username and/or password"
          : err?.error?.detail;
      setError(error || ErrorMessages.badCredentials);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className={styles.bg_image}>
      <Row className={`${styles.main_row} gx-0`}>
        <Col md={6} className={styles.logo_col}>
          <img src={MusicFxLogoBig} alt="MusicFx" />
        </Col>
        <Col md={6} sm={12} className={styles.reg_col}>
          <form name='formLogin' noValidate onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.container}>
              <div className={styles.heading}>
                <h3>Log In</h3>
              </div>

              <GoogleLogin
                clientId={CommonConstant.googleClientId}
                buttonText="Log In with Google"
                onSuccess={googleLoginInit}
                cookiePolicy={'single_host_origin'}
                responseType='code,token'
                className={styles.reg_google}
              />

              <div className={styles.or}>
                <p>or</p>
              </div>
              <div className={styles.email}>
                <TextField
                  ref={login}
                  errors={errors?.username}
                  placeholder="Email Address"
                  name="username"
                  hideErrors={true}
                  color="#ffffff"


                />
              </div>
              {errors?.username?.message && (
                <div className={styles.error} >
                  {errors?.username?.message}.
                </div>
              )}
              <div className={styles.password}>
                <TextField
                  type='password'
                  ref={login}
                  errors={errors?.password}
                  placeholder="Password"
                  name="password"
                  hideErrors={true}
                  color="#ffffff"
                />

              </div>
              {errors?.password?.message && (
                <div className={styles.error} >
                  {errors?.password?.message}.
                </div>
              )}
              {errors?.message && (
                <div className={styles.error} >
                  {errors?.message}.
                </div>
              )}
              {error && <div className="text-danger mb-2">
                {error}
              </div>}
              <div className={styles.check}>
                <Link to="/forgot-password">Forgot Password?</Link>{" "}
              </div>
              <div className={styles.reg_btn}>
                <button type="submit">
                  {
                    loading ?
                      <Spinner animation="border" variant="light" />
                      :
                      'LOG IN'

                  }
                </button>
              </div>
              <div className={styles.have_account}>
                <p>
                  Don't have an account?
                  <span>
                    <Link to="/register">Register.</Link>{" "}
                  </span>
                </p>
              </div>
            </div>
          </form>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
