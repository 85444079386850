import React from 'react';
import Footer from "../../Components/Footer/Footer";
import Register from "../../Components/Register/Register";
import Header from "../../Components/Header/Header";

const RegisterPage = () => {
    console.log("HERE")
    return (
        <div>
            <Header />
            <Register />
            <Footer />

        </div>
    )
}

export default RegisterPage;
