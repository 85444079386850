export const CommonConstant = {
	user: "user",
	token: "token",
	tenant: "tenant",
	defaultPageSize: 25,
	phoneRegExp: /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
	googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID
};

export const Roles = {
	marketplace: "R_MARKET",
	sellCard: "R_SELL_CARD",
	cashout: "R_CASH_OUT"
}

export const idsToShowUserName = (process.env.REACT_APP_ID_TO_SHOW_USERNAME || '').split(",").map(x => +x);
export const cardsToShowUserName = (process.env.REACT_APP_CARD_TO_SHOW_USERNAME || '').split(",").map(x => x.toLowerCase())

export const ErrorMessages = {
	default: 'Something went wrong, please contact administrator.',
	badCredentials: 'Invalid username and/or password.',
	badEmail: 'Invalid email address.'
}

export const TransactionStatus = {
	success: "SUCCESS",
	fail: "FAIL",
	pending: "PENDING",
	crypto_waiting: "WAITING_FOR_CRYPTO_CHARGE_CONFIRMATION"
}
