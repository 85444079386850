import React,{ useState,Fragment,useEffect } from "react";
import styles from "./App.module.scss";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import { Button,ButtonGroup } from "reactstrap";
import "./override.scss";
import Marketplace from "../../Components/MarketPlace/MarketPlace";
import Dashboard from "../../Components/Dashboard/Dashboard";
import { useParams } from "react-router";

const App = () => {
  let { key } = useParams();

  const [tab,setTab] = useState();

  useEffect(() => {
    setTab(key);
  },[key]);

  const styleHandler = (selected) => {
    if (tab === selected) {
      return styles.button_active;
    } else {
      return styles.button_inactive;
    }
  };

  const SelectedItem = [
    {
      tabId: "marketplace",
      text: "Marketplace",
      component: <Marketplace />,
    },
    {
      tabId: "dashboard",
      text: "My Dashboard",
      component: <Dashboard />,
    },
  ];

  return (
    <div>
      <Header />
      <div className={`${styles.main_container}`}>
        <div className={styles.page_header}>
          <h1>Marketplace</h1>

          <div className={styles.group_button_container}>
            <ButtonGroup className={styles.divider}>
              <Button
                className={styleHandler("marketplace")}
                onClick={(e) => setTab("marketplace")}
              >
                Marketplace
              </Button>
              <Button
                className={styleHandler("dashboard")}
                onClick={(e) => setTab("dashboard")}
              >
                Dashboard
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </div>

      <div>
        {SelectedItem.map((item) => (
          <Fragment key={item.tabId}>
            {item.tabId === tab ? item.component : null}
          </Fragment>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default App;
