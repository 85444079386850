import React,{ useEffect,useMemo,useState } from "react";
import styles from "./BuyNft.module.scss";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardSubtitle,
  Row,
  Col,
} from "reactstrap";
import discover from "../../Assets/discover.svg";
import visa from "../../Assets/visa.svg";
import master from "../../Assets/master.svg";
import "./override.scss";
import CommonUtility from "../../utility/common";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { CardsService,PacksService } from "../../utility/services";
import { HistoryByIdHook } from "../../hooks/user";
import {
  ErrorMessages,
  TransactionStatus,
} from "../../utility/constants/common";
import { OverlayLoadingBar } from "../LoadingBar/LoadingBar";
import { SavedCardHook } from "../../hooks/payment";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "../../elements/Inputs";
import Congratulation from "../Congratulation/Congratulation";
import { disableBodyScroll,enableBodyScroll } from "body-scroll-lock";
import CoinbaseCommerceButton from "react-coinbase-commerce";
import { useHistory } from "react-router-dom";
import { AuthHook } from "../../context/auth";
import { idsToShowUserName } from "../../utility/constants/common";
import { BsChevronLeft } from "react-icons/bs";
import { BackButton } from "../../elements/BackButton/BackButton";

const paymentSchema = yup.object().shape({
  name: yup.string().required("Card Holder Name is required"),
  saveCard: yup.boolean(),
});

const useOptions = () => {
  const fontSize = "1.125rem";
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          letterSpacing: "0.025em",
        },
        invalid: {
          color: "#dc3545",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const BuyNft = ({ item,id,type }) => {
  const history = useHistory();
  const stripe = useStripe();
  const options = useOptions();
  const elements = useElements();
  const [error,setError] = useState("");
  const [processing,setProcessing] = useState(false);
  const [transactionId,setTransactionId] = useState("");
  const {
    data: transaction,
    error: transactionError,
    refreshData,
  } = HistoryByIdHook(transactionId);
  const { user } = AuthHook();
  const { data: card,loading: cardLoading } = SavedCardHook();
  const [success,setSuccess] = useState(false);
  const [terms,setTerms] = useState(false);
  const [chargeCode,setChargeCode] = useState("");
  const {
    register: payment,
    errors,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(paymentSchema),
  });

  useEffect(() => {
    if (transactionError) {
      const targetElement = document.querySelector("body");
      enableBodyScroll(targetElement);

      setError(transactionError?.error?.title || ErrorMessages.default);
      setTransactionId(null);
    }
  },[transactionError]);

  useEffect(() => {
    const fetch = async () => {
      switch (transaction?.transactionStatus) {
        case TransactionStatus.crypto_waiting:
          setChargeCode(transaction?.cryptoChargeCode);
          break;
        case TransactionStatus.success:
          setTransactionId("");
          await claimPack(transaction?.pack?.id);
          onTransactionComplete();
          break;

        case TransactionStatus.fail:
          setTransactionId("");
          setError(transaction?.notes);
          break;

        default:
          break;
      }
    };
    fetch();
  },[transaction]);

  useEffect(() => {
    if (chargeCode) {
      setTimeout(() => {
        const button = document.getElementsByClassName("coinbase-button");
        if (button.length > 0) {
          button[0].click();
        }
      },500);
    }
  },[chargeCode]);
  useEffect(() => {
    if (!transactionId) return;

    const targetElement = document.querySelector("body");
    disableBodyScroll(targetElement);
    const interval = setInterval(() => {
      refreshData();
    },5000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  },[transactionId]);

  const buy = async (formData) => {
    if (!stripe || !elements) {
      return;
    }
    setProcessing(true);
    const { error,paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name: formData.name,
      },
    });

    if (error) {
      setProcessing(false);
      setError(error.message);
    } else {
      try {
        let result;
        // await PaymentService.add(paymentMethod.id);
        if (type === "pack") {
          result = await PacksService.buy(id,{
            paymentMethodId: paymentMethod.id,
            saveCard: formData.saveCard,
          });
        } else if (type === "card") {
          result = await CardsService.buy(id,{
            paymentMethodId: paymentMethod.id,
            saveCard: formData.saveCard,
          });
        }

        console.log(result);
        setTransactionId(result?.id);
      } catch (error) {
        // setError(error?.error?.title || ErrorMessages.default);
      } finally {
        setProcessing(false);
      }
    }
  };

  const buyUsingSavedCard = async () => {
    try {
      setProcessing(true);
      let result;
      if (type === "pack") {
        result = await PacksService.buy(id,null);
      } else if (type === "card") {
        result = await CardsService.buy(id,null);
      }
      setTransactionId(result?.id);
    } catch (error) {
      setError(error?.error?.title || ErrorMessages.default);
    } finally {
      setProcessing(false);
    }
  };

  const claimPack = async (id) => {
    try {
      const result = await PacksService.open(id);
      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };
  const buyUsingCrypto = async () => {
    try {
      setProcessing(true);
      let result;
      if (type === "pack") {
        result = await PacksService.buy(id,{ isCrypto: true });
      } else if (type === "card") {
        result = await CardsService.buy(id,{ isCrypto: true });
      }
      setTransactionId(result?.id);
    } catch (error) {
      setError(error?.error?.title || ErrorMessages.default);
    } finally {
      setProcessing(false);
    }
  };

  const coinBaseError = (error) => {
    setTransactionId("");
    setError(error);
  };

  const onCloseCoinBase = () => {
    history.push(`/buy-nft?id=${id}&type=${type}`);
  };
  const onTransactionComplete = () => {
    const targetElement = document.querySelector("body");
    enableBodyScroll(targetElement);
    setSuccess(true);
  };
  return (
    <div className={styles.main_container}>

      <BackButton text='Back to Marketplace' />

      <Row className={`${styles.row} gx-0`}>
        <Col md={12} sm={12} lg={5} className={styles.left_col}>
          <div className={styles.Wrapper}>
            <p>PURCHASING:</p>
            <p>{item?.name}</p>
          </div>
          <div>
            <Card>
              <CardImg
                top
                width="100%"
                src={item?.picture?.url}
                alt="Card image cap"
              />
              <CardBody>
                <div className={styles.divider}>
                  <div className={styles.left}>
                    {idsToShowUserName?.includes(+id) && (
                      <CardSubtitle>{user.fullName}</CardSubtitle>
                    )}
                    {/* {!idsToShowUserName?.includes(+id) ||
                      (cardsToShowUserName?.includes(item?.release) && (
                        <CardSubtitle>{user.fullName}</CardSubtitle>
                      ))} */}

                    {/* <CardSubtitle>{item.name}</CardSubtitle> */}
                    {/* <CardText>{item?.description}</CardText> */}
                  </div>
                  <div className={styles.right}></div>
                  <CardText>
                    {CommonUtility.currencyFormat(item.price / 100)}
                  </CardText>
                </div>
              </CardBody>
            </Card>

          </div>
        </Col>
        <Col md={12} sm={12} lg={6} className={styles.right_col}>
          <div className={styles.div}>
            <h4>Select Method</h4>
            {type === "pack" && (
              <>
                <div className={styles.buy_btn}>
                  <button disabled={processing} onClick={buyUsingCrypto}>
                    {processing ? "Processing" : "Pay with Crypto"}
                  </button>
                </div>
              </>
            )}
            <p className={styles.or}>OR</p>
            <form onSubmit={handleSubmit(buy)}>
              <div className={`${styles.cards_container} gx-0`}>
                <div md={1} sm={3}>
                  <img src={visa} alt="Visa" />
                </div>
                <div md={1} sm={3}>
                  <img src={master} alt="Master" />
                </div>
                <div md={1} sm={3}>
                  <img src={discover} alt="Discover" />
                </div>
              </div>
              {!cardLoading && card?.last4 && (
                <div className={styles.buy_btn}>
                  <h4 className={styles.payment_heading}>Use Existing Card</h4>
                  <button
                    type="button"
                    onClick={buyUsingSavedCard}
                    disabled={processing}
                  >
                    {processing
                      ? "Processing"
                      : `Use Saved Card ${card.last4}(${card.expMonth}/${card.expYear})`}
                  </button>
                </div>
              )}

              <h4 className={styles.payment_heading}>Payment Details</h4>
              <div className={styles.input}>
                <CardNumberElement options={options} className="textfield-1" />
              </div>
              <div className={styles.input}>
                <CardExpiryElement options={options} className="textfield-1" />
              </div>
              <div className={styles.input}>
                <CardCvcElement options={options} className="textfield-1" />
              </div>
              <div className={styles.input}>
                <TextField
                  placeholder="CARD HOLDER NAME"
                  name="name"
                  errors={errors?.name}
                  ref={payment}
                />
              </div>
              <div className={styles.input}>
                <label className="checkbox">
                  <input type="checkbox" name="saveCard" ref={payment} />
                  <span className="default"></span>
                  Save card for future payment
                </label>
              </div>
              <div className={styles.input}>
                <label className="checkbox">
                  <input
                    type="checkbox"
                    name="terms"
                    checked={terms}
                    onChange={() => setTerms(!terms)}
                  />
                  <span className="default"></span>
                  {/* I agree to be bound by the Parker McCollum <a href='https://musicfx.io/the-parker-mccollum-fan-club-golden-ticket-2022-sweepstakes/' target='_blank'>Sweepstakes Rules</a> */}
                  I agree to the{" "}
                  <a
                    href="https://musicfx.io/terms-of-service/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Terms of Service
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://musicfx.io/privacy-policy/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </label>
              </div>
              {error && <p className={styles.danger}>{error}</p>}
              <div className={styles.buy_btn}>
                <button type="submit" disabled={!terms}>
                  {processing ? "Proccessing..." : "BUY NOW"}
                </button>
              </div>
              <p className={`mt-3 ${styles.info}`}>
                MusicFX uses MetaWorks Inc. (CSE: CWRK and OTCQB: CWRK) and
                third party software providers to process payments on behalf of
                MusicFX. As a result receipts for purchases will be sent from
                MetaWorks Inc., and CWRK may show up on your bank or credit
                card statement.
              </p>
            </form>
          </div>
        </Col>
      </Row>
      <CoinbaseCommerceButton
        styled={false}
        chargeId={chargeCode}
        onChargeSuccess={(e) => onTransactionComplete()}
        onChargeFailure={(e) => coinBaseError(e.code)}
        onModalClosed={() => onCloseCoinBase()}
        className="coinbase-button"
      >
        Complete Crypto Transaction
      </CoinbaseCommerceButton>
      {transactionId && (
        <OverlayLoadingBar
          dark={true}
          title={"We are processing your order, please wait..."}
        />
      )}
      <Congratulation show={success} onHide={() => setSuccess(false)} />
    </div>
  );
};

export default BuyNft;
