import { useEffect,useState } from "react"
import { CardsService,PackTemplatesService,PaymentService } from "../utility/services";
import { ErrorMessages } from "../utility/constants/common";

export const SavedCardHook = () => {

    const [data,setData] = useState({});
    const [loading,setLoading] = useState(true);
    const [error,setError] = useState('');

    const fetchData = async () => {
        try {
            setLoading(true);
            const result = await PaymentService.savedCard();
            setData(result);
        } catch (error) {
            setError(error.message || ErrorMessages.default);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    },[])

    return { data,loading,error };
}

export const DetailsHook = (id,type) => {

    const [data,setData] = useState({});
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState('');

    const fetchPackData = async () => {
        try {
            setLoading(true);
            const result = await PackTemplatesService.getById(id);
            setData(result);
        } catch (error) {
            setError(error.message || ErrorMessages.default);
        } finally {
            setLoading(false);
        }
    }

    const fetchCardData = async () => {
        try {
            setLoading(true);
            const result = await CardsService.getById(id);
            setData(result);
        } catch (error) {
            setError(error.message || ErrorMessages.default);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (id) {
            if (type === "pack") {
                fetchPackData();
            } else if (type === "card") {
                fetchCardData();
            }
        }
        // eslint-disable-next-line
    },[id,type])

    return { data,loading,error };
}

export const CryptoChargeHook = (id) => {

    const [data,setData] = useState(null);
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState('');

    const fetchData = async () => {
        try {
            setLoading(true);
            const result = await PaymentService.cryptoCharge(id);
            setData(result.data);
        } catch (error) {
            setError(error.message || ErrorMessages.default);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (id) {
            fetchData();
        }
        // eslint-disable-next-line
    },[id])

    return { data,loading,error };
}