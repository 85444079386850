import React from "react";
import { BrowserRouter as Router,Switch } from "react-router-dom";
import App from "../Pages/App/App";
import BuyNftPage from "../Pages/BuyNft/BuyNft";
import Register from "../Pages/Register/RegisterPage";
import Login from "../Pages/Login/Login";
import CardDetails from "../Pages/CardDetails/CardDetails";
import AccountVerification from "../Pages/Account-Verify/Account-Verify"
import ForgotPassword from "../Pages/Forgot-Password/Forgot-Password";
import ResetPassword from "../Pages/Reset-Password/Reset-Password";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import PublicRoute from "./PublicRoute/PublicRoute";
import { ScrollToTop } from "../Components/ScrollToTop";
import EditProfile from "../Pages/Edit-Profile/EditProfile";
import ChangePassword from "../Pages/Change-Password/ChangePassword";

const AppRoutes = () => {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Switch>
          <PublicRoute exact path="/" component={Login} />
          <PrivateRoute exact path="/app/:key" component={App} />
          <PublicRoute exact path="/register" component={Register} />
          <PrivateRoute exact path="/buy-nft" component={BuyNftPage} />
          <PrivateRoute exact path="/view-card/:id" component={CardDetails} />
          <PublicRoute exact path="/register-activation" component={AccountVerification} />
          <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
          <PublicRoute exact path="/reset-password" component={ResetPassword} />
          <PrivateRoute exact path="/edit-profile" component={EditProfile} />
          <PrivateRoute exact path="/change-password" component={ChangePassword} />
          <PrivateRoute path="*" component={Login} />

        </Switch>
      </Router>
    </>
  );
};

export default AppRoutes;
