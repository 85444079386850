import React from 'react';
import Footer from "../../Components/Footer/Footer";
import ResetScreen from "../../Components/Reset-Screen/Reset-Screen";
import Header from "../../Components/Header/Header";

const ResetPassword = () => {
    return (
        <div>
            <Header />
            <ResetScreen />
            <Footer />
        </div>
    )
}

export default ResetPassword;
