import React, { useState } from "react";
import fb from "../../Assets/fb.svg";
import twitter from "../../Assets/twitter.svg";
import instagram from "../../Assets/instagram.svg";
import tiktok from "../../Assets/tiktok.svg";
import User from "../../Assets/user.png";
import logo from "../../Assets/logo_light.svg";
import SideNav from "./SideNav/SideNav";
import "./override.scss";
import styles from "./Header.module.scss";
import { Link, useHistory } from "react-router-dom";
import { UserService } from "../../utility/services";
import { AuthHook } from "../../context/auth";
import twitch from "../../Assets/twitch.svg";
import youtube from "../../Assets/youtube.svg";

const Header = () => {
  const history = useHistory();
  const [dropdown, setDropdown] = useState(false);
  const {
    isAuthenticated: isLoggedIn,
    logout: logoutContext,
    user,
  } = AuthHook();

  const Login = () => {
    history.push("/");
  };

  const toggle = () => {
    setDropdown(!dropdown);
  };

  const logout = () => {
    UserService.logout();
    logoutContext();
    history.push("/");
  };

  return (
    <>
      <div>
        <SideNav />
      </div>
      <div className={styles.header}>
        <nav className="navbar navbar-expand-lg navbar-light header-nav ">
          <a
            className="navbar-brand  mr-4"
            href="https://musicfx.io/"
            target="_blank"
            rel="noreferrer"
          >
            <img width="137px" src={logo} alt="MusicFx" />
          </a>
          <div className=" navbar-collapse" id="navbarText">
            <ul className="navbar-nav me-auto navbar-list ">
              <li className="nav-item  px-2">
                <a className="nav-link" href="https://musicfx.io">
                  HOME
                </a>
              </li>
              <li className="nav-item px-3">
                <a className="nav-link" href="https://musicfx.io/about/">
                  ABOUT
                </a>
              </li>
              {/* <li className="nav-item px-4">
                <a
                  className="nav-link"
                  to={{ pathname: `https://musicfx.io/release/` }}
                  target="_blank"
                >
                  RELEASES
                </a>
              </li> */}
              <li className="nav-item px-2">
                <a className="nav-link" href="https://musicfx.io/support/">
                  SUPPORT
                </a>
              </li>
              <li className="nav-item px-2">
                <a className="nav-link" href="https://musicfx.io/news/">
                  NEWS
                </a>
              </li>
              <li className="nav-item px-3">
                <a className="nav-link" href="https://musicfx.io/blog/">
                  BLOG
                </a>
              </li>
              {/* <li className="nav-item px-3">
                <Link
                  className="nav-link"
                  to={{ pathname: `https://musicfx.io/podcasts/` }}
                  target="_blank"
                >
                  PODCASTS
                </Link>
              </li> */}
            </ul>
            <span className="navbar-text">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item vertical-align  px-1">
                  <a
                    className="nav-link "
                    href="https://www.facebook.com/Music-FX-Digital-101314978940493"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    {/* <img width="16px" src={fb} alt="fb" /> */}
                    <i className="fab fa-facebook-f vertical-icon-align"></i>
                  </a>
                </li>
                <li className="nav-item vertical-align px-1">
                  <a
                    className="nav-link"
                    href="https://twitter.com/MusicFXDigital"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <img width="17px" src={twitter} alt="twitter" />
                  </a>
                </li>
                <li className="nav-item vertical-align px-1">
                  <a
                    className="nav-link"
                    href="https://www.instagram.com/musicfxdigital"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <img width="17px" src={instagram} alt="instagram" />
                  </a>
                </li>
                {/* <li className="nav-item px-2">
                  <Link
                    className="nav-link"
                    to={{ pathname: `https://www.tiktok.com/@musicfxdigital` }}
                    target="_blank"
                  >
                    <img width="17px" src={tiktok} alt="tiktok" />
                  </Link>
                </li> */}
                {/* <li className="nav-item px-2">
                  <Link
                    className="nav-link"
                    to={{ pathname: `https://www.twitch.tv/musicfxdigital` }}
                    target="_blank"
                  >
                    <img width="17px" src={twitch} alt="twitch" />
                  </Link>
                </li> */}
                <li className="nav-item vertical-align px-1">
                  <a
                    className="nav-link"
                    href="https://www.youtube.com/channel/UCS4MB6a8O9rL1pGBKEv7Jmg"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <img width="18px" src={youtube} alt="youtube" />
                  </a>
                </li>
                {isLoggedIn ? (
                  <li className={`nav-item px-3 ${styles.avatar_container}`}>
                    <button className={styles.avatar} onClick={toggle}>
                      <img src={user?.imageUrl || User} alt="User" />
                    </button>
                    {dropdown && (
                      <ul className={styles.dropdown}>
                        <li onClick={() => history.push("/app/marketplace")}>
                          MarketPlace
                        </li>
                        <li onClick={() => history.push("/app/dashboard")}>
                          Dashboard
                        </li>
                        <li onClick={() => history.push("/edit-profile")}>
                          Edit Profile
                        </li>
                        {user?.hasPassword && (
                          <li onClick={() => history.push("/change-password")}>
                            Change Password
                          </li>
                        )}

                        <li onClick={() => logout()}>Logout</li>
                      </ul>
                    )}
                  </li>
                ) : (
                  <li className="nav-item  ">
                    <a className="nav-link" href="/#">
                      <button onClick={Login}>LOG IN</button>
                    </a>
                  </li>
                )}
              </ul>
            </span>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
