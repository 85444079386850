import { BsChevronLeft } from "react-icons/bs";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import styles from "./BackButton.module.scss";

export const BackButton = ({ text = "Back" }) => {
  const history = useHistory();
  return (
    <Button
      className={styles.back_button}
      onClick={() => history.push("/app/dashboard")}
    >
      <BsChevronLeft />
      {text}
    </Button>
  );
};
