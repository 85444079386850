import React from 'react';
import Footer from "../../Components/Footer/Footer";
import Verify from "../../Components/Verify/Verify";
import Header from "../../Components/Header/Header";

const LoginPage = () => {
    return (
        <div>
            <Header/>
            <Verify/>
            <Footer/>
        </div>
    )
}

export default LoginPage;
