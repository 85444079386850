import React from "react";
import styles from "./style.module.scss";
import { Row,Col } from "reactstrap";
import ReactPlayer from "react-player";
import "./override.scss";
import { cardsToShowUserName } from "../../utility/constants/common";
import { useMemo } from "react";
import { BackButton } from "../../elements/BackButton/BackButton";

const Video = ({ details }) => {

  const showUserName = useMemo(() => details?.release,cardsToShowUserName?.includes((details?.release || '').toLowerCase()),
    [details,cardsToShowUserName])

  return (
    <div className={styles.main_container}>
      {/* <div className={styles.buttons_container}> */}
      {/* <Button onClick={() => history.push("/app/dashboard")}>
                    BACK TO MY DASHBOARD
                </Button> */}
      {/* <Button onClick={copyToClipboard} id="Copy">
                    {hoverText}
                </Button> */}
      {/* </div> */}
      <div className={`${styles.second_container} container`}>
        <Row className={`${styles.main_div} gx-0`}>

          <div className={styles.buttons_container}>
            <BackButton text="Back to Dashboard" />
          </div>

          <Col md={12} lg={6} sm={12} className={styles.col1_container}>
            {details?.video?.url ? (
              <div className={styles.video_card}>
                <ReactPlayer
                  playsinline
                  muted={true}
                  playing
                  loop
                  className={styles.imgContainer}
                  url={details?.video?.url}
                />
                {showUserName && <p>{details?.owner?.fullName}</p>}
              </div>
            ) : (
              <div>
                <img
                  src={details?.attachments[0]?.url}
                  alt="Card"
                  className={styles.imgContainer}
                />
                {showUserName && <p>{details?.owner?.fullName}</p>}
              </div>
            )}
          </Col>
          <Col md={12} lg={6} sm={12} className={styles.col2_container}>
            <p className={styles.heading}>{details?.name}</p>
            <p>Your Membership Includes the following:</p>
            {details?.text ? (
              <div
                className={styles.detail_div}
                contentEditable="false"
                dangerouslySetInnerHTML={{ __html: details?.text }}
              ></div>
            ) : null}
            <p>Membership Number: {details?.groupSequence}</p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Video;
