import axios from 'axios';
import { UserService } from './user';


const baseURL = process.env.REACT_APP_API_PATH;

const onSuccess = (response) => {
    console.debug('Request Successful!',response);
    return response.data;
}

const onError = async (error) => {
    console.debug('Request Failed:',error);

    console.debug('Request Failed:',error.config);

    if (error.response) {
        // Request was made but server responded with something
        // other than 2xx
        console.debug('Status:',error.response.status);
        console.debug('Data:',error.response.data);
        console.debug('Headers:',error.response.headers);

        if (error.response.status === 401 && error.response.config?.url.indexOf("api/authenticate") === -1) {
            window.localStorage.clear();
            window.location.href = '/';
            return Promise.reject(error);
        }
    }

    return Promise.reject({ error: error?.response?.data?.error || error.response?.data,status: error?.response?.status });
}

const request = async (options,isSecure) => {

    let headers = {};
    headers['Content-Type'] = 'application/json'

    if (isSecure) {
        const token = UserService.getToken();
        headers['Authorization'] = `Bearer ${token}`;
    }

    const tenant = UserService.getTenant();
    if (tenant) {
        headers['tenant'] = tenant;
    }

    const client = axios.create({
        baseURL,
        headers: { ...headers }
    });

    client.defaults.headers.post['Access-Control-Allow-Origin'] = '*';


    return client(options)
        .then(onSuccess)
        .catch(onError);
}

const uploadFiles = (url,data,headers) => {

    const client = axios({
        url,
        method: "PUT",
        headers: { ...headers },
        data
    });

    return client
        .then(onSuccess)
        .catch(onError);
}


export class BaseService {

    static get = (url,isSecure = true) => {
        return request({
            url,
            method: 'GET'
        },isSecure);
    }

    static post = (url,data,isSecure = true) => {
        return request({
            url,
            method: 'POST',
            data
        },isSecure);
    }

    static put = (url,data,isSecure = true) => {
        return request({
            url,
            method: 'PUT',
            data
        },isSecure);
    }

    static remove = (url,isSecure = true) => {
        return request({
            url,
            method: 'DELETE',
        },isSecure);
    }

    static upload = (url,data,header) => {
        return uploadFiles(url,data,header);
    }

}