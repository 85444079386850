import React from 'react';
import Footer from "../../Components/Footer/Footer";
import ForgotScreen from "../../Components/Forgot-Screen/Forgot-Screen";
import Header from "../../Components/Header/Header";

const ForgotPassword = () => {
    return (
        <div>
            <Header/>
            <ForgotScreen/>
            <Footer/>
        </div>
    )
}

export default ForgotPassword;
