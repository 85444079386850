import { createContext,useEffect,useState,useContext,useMemo } from "react";
import { Redirect } from "react-router";
import { Route } from "react-router-dom";
import { UserService } from "../utility/services";
import React from "react";
import { Roles } from "../utility/constants/common";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated,setIsAuthenticated] = useState(null);
  const [user,setUser] = useState(null);
  const [loading,setLoading] = useState(true);

  useEffect(() => {
    const loadToken = () => {
      setIsAuthenticated(UserService.isAuthenticated());
      setUser(UserService.getUser());
      setLoading(false);
    };
    loadToken();
  },[]);

  const login = (data) => {
    setIsAuthenticated(true);
    setUser(data);
    UserService.storeUser(data);
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{ user,loading,isAuthenticated,login,logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthHook = () => useContext(AuthContext);

export const ProtectedRoute = ({ children,...rest }) => {
  const { isAuthenticated,loading,user } = AuthHook();
  const allowedMarketplace = useMemo(
    () => (user?.authorities || []).includes(Roles.marketplace),
    [user]
  );

  if (loading) {
    return null;
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        const pathname = props.location.pathname;
        return isAuthenticated ? (
          <>
            {pathname !== "/app/marketplace" ? (
              children
            ) : allowedMarketplace ? (
              children
            ) : (
              <Redirect
                to={{
                  pathname: "/",
                }}
              />
            )}
          </>
        ) : (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        );
      }}
    />
  );
};
