const API_Path = process.env.REACT_APP_API_PATH
export const APIPath = {
  branding: `${API_Path}branding`,

  // User
  login: `${API_Path}authenticate`,
  register: `${API_Path}register`,
  registerActivate: `${API_Path}activate`,
  forgotPassword: `${API_Path}account/reset-password/init`,
  resetPassword: `${API_Path}account/reset-password/finish`,
  changePassword: `${API_Path}account/change-password`,
  sendMobileVerification: `${API_Path}account/mobile-verification/ask`,
  verifyMobile: `${API_Path}account/mobile-verification/verify`,
  account: `${API_Path}account`,
  userHistory: `${API_Path}card-transactions`,

  // Cards
  cards: `${API_Path}cards`,
  myCards: `${API_Path}cards/mine`,
  sellCard: `${API_Path}cards/market/sell`,
  cardHistory: `${API_Path}card-events/by-card`,
  totalNfts:`${API_Path}nfts/mine/count`,
  // Marketplace
  marketplace: `${API_Path}cards/market`,
  buyCard: `${API_Path}cards/market/buy`,

  // Packs
  packs: `${API_Path}packs`,
  buyPack: `${API_Path}packs/buy`,
  myPacks: `${API_Path}packs/mine`,
  packTemplates: `${API_Path}pack-templates`,
  openPack: `${API_Path}packs/open`,

  // Payment
  addPayment: `${API_Path}payment/add`,
  savedCard: `${API_Path}payment/get`,
  cashout: `${API_Path}payouts/cashout`,
  cryptoCharge: `${API_Path}crypto/charge`,
};
