import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import styles from "./Reset-Screen.module.scss";
import { Link, useLocation } from "react-router-dom";
import { Spinner } from "reactstrap";
import { UserService } from "../../utility/services";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "../../elements/Inputs";
import { ErrorMessages } from "../../utility/constants/common";

const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(4, "Password should be must be between 4 to 16")
    .max(16, "Password should be must be between 4 to 16"),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const ResetScreen = () => {
  let { search } = useLocation();

  const {
    register: resetPassword,
    errors,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(resetPasswordSchema),
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("");
  const [keyError, setKeyError] = useState("");

  useEffect(() => {
    const key = new URLSearchParams(search).get("token");
    if (key) {
      setKey(key);
    } else {
      setKeyError("Invalid Link, Please contact administrator");
    }
  }, [search]);

  const onSubmit = async (data) => {
    try {
      setSuccess(false);
      setLoading(true);
      await UserService.resetPassword({
        key,
        newPassword: data.password,
      });
      setSuccess(true);
    } catch (err) {
      console.log(err);
      setError(ErrorMessages.badCredentials);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className={styles.bg_image}>
        <Row className={`${styles.main_row} gx-0`}>
          <Col md={6} sm={12} className={styles.reg_col}>
            <div className={styles.container}>
              <div className={styles.heading}>
                <h3>RESET PASSWORD</h3>
              </div>
              {!keyError ? (
                <>
                  {!success ? (
                    <form
                      name="formLogin"
                      noValidate
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className={styles.password}>
                        <TextField
                          type="password"
                          ref={resetPassword}
                          errors={errors?.password}
                          placeholder="Password"
                          name="password"
                          hideErrors={true}
                          color="#ffffff"
                        />
                      </div>
                      {errors?.password?.message && (
                        <div className={styles.error}>
                          {errors?.password?.message}.
                        </div>
                      )}
                      <div className={styles.password}>
                        <TextField
                          type="password"
                          ref={resetPassword}
                          errors={errors?.confirmPassword}
                          placeholder="Confirm Password"
                          name="confirmPassword"
                          hideErrors={true}
                          color="#ffffff"
                        />
                      </div>
                      {errors?.confirmPassword?.message && (
                        <div className={styles.error}>
                          {errors?.confirmPassword?.message}.
                        </div>
                      )}

                      {error && <div className="text-danger mb-2">{error}</div>}

                      <div className={styles.reg_btn}>
                        <button type="submit">
                          {loading ? (
                            <Spinner animation="border" variant="light" />
                          ) : (
                            "Continue"
                          )}
                        </button>
                      </div>
                    </form>
                  ) : (
                    <p className="register-text-style mt-3">
                      Password Reset successfully. Please login again to access
                      the application.
                    </p>
                  )}
                </>
              ) : (
                <p className="register-text-style mt-3">{keyError}</p>
              )}
              <div className={styles.have_account}>
                <Link to={"/"}>Back to Login</Link>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ResetScreen;
